import 'leaflet/dist/leaflet.css';
import styles from './Venues.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const Venues = () => {
    return(
        <Container>
        <Show>
        <div className={styles.wrapper} id={'venues'}>
            <div className={styles.title}>Venues</div>
            <div className={styles.map}>
                <img src="/images/map.png"/>
            </div>
            <div className={styles.descriptionTitle}>Kino International, Karl-Marx-Allee, Berlin</div>
            <div className={styles.descripiton}>A historic cinema located in central Berlin, known for its architectural beauty and its role in film premieres. Images of the venue will be displayed, focusing on its classic 1960s architecture.</div>
        </div>
        </Show>
        </Container>
    );
};

export default Venues