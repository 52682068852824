import styles from './Awards.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";

const nomination = [
    { text: "Best Feature Film", logo: '/images/logo1.png' },
    { text: "Best Documentary", logo: '/images/logo2.png' },
    { text: "Best Short Film", logo: '/images/logo3.png' },
    { text: "Best Cinematography", logo: '/images/logo4.png' },
    { text: "Best Urban Film", logo: '/images/logo5.png' },
    { text: "Best Screenplay", logo: '/images/logo6.png' },
];

const Awards = () => {
    return(
        <Show>
            <Container>
                <div className={styles.wrapper} id={'awards'}>
                    <div className={styles.inner}>
                        
                        <div className={styles.title}>Award & Categories</div>
                        <div className={styles.awards}>
                            {nomination && nomination.map((award, index) => (
                                <div key={index} className={styles.award}>
                                    {award.logo && <img src={award.logo} alt={award.text} className={styles.image} />}
                                    <div className={styles.text}>{award.text}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </Show>
    );
};

export default Awards;
