import styles from './Experience.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const Experience = () => {
    return(
        <Show>

        <Container>
        <div className={styles.wrapper}>
            <div className={styles.inner}>
            <div className={styles.tittle}>Experience the Urban Film Festival</div>
            <div className={styles.subTittle}>Join us for a celebration of urban cinema and culture in the heart of Berlin.</div>
            <div className={styles.buttons}>
                <a href='#about'>
                <button className={styles.learn}>Learn More</button>
                </a>
                <a href='https://filmfreeway.com/Berlinurbanfestival'>
                <button className={styles.submit}>Submit</button>
                </a>
            </div>
            </div>
        </div>
        </Container>
        </Show>

    );
};

export default Experience