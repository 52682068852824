import styles from './About.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const About = () => {
    return(
        <Container>
        <Show>
        <div className={styles.wrapper} id={'about'}>
            <div className={styles.words}>
            <div className={styles.tittle}>About Us</div>
            <div className={styles.text}>
                The Berlin Urban Film Festival offers a platform to films that explore the dynamics of social interactions within large communities. These films not only showcase the physical spaces of urban environments, but also delve into the diverse cultures, communities, and environmental influences that shape them.
                We collect stories from filmmakers who are passionate about exploring immigration, inclusion and social issues. The festival is interested in films about the strength and resilience of communities, cultural phenomena and the personal stories of individuals in society. The Berlin Urban Film Festival strives to present a comprehensive picture of social problems and the everyday life of communities.
            </div>
            <div className={styles.text}>
            Leading up to the highly anticipated Berlin Urban Film Festival, which is set to take place on 15-16 May 2025, there will be two preliminary seasons. These seasons will offer filmmakers from all corners of the globe a unique chance to submit their work for potential inclusion in the festival lineup. Taking place in May 2024, these seasons will feature online screenings where a panel of judges will carefully assess the submitted films. The festival jury will ultimately handpick the works that best exemplify excellence and originality. While films exploring the aforementioned themes will receive special consideration, the festival is open to a wide range of subjects. It is important to note, however, that due to time constraints, only a limited number of films will be showcased during the main event.
            </div>
            </div>
            <div className={styles.logo}>
            <img src={"/images/goodlogo.png"}/>
            </div>
        </div>
        </Show>
        </Container>
    );
};

export default About