import styles from './Banner.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const Banner = () => {
    return(
        <Container>
        <Show>
        <div className={styles.wrapper}>
            <div className={styles.tittle}>Experience the vibrant <b>Berlin Urban</b> film festival</div>
            <div className={styles.subTittle}>Immerse yourself in the cutting-edge world of urban cinema 15 and 16 May 2025</div>
            <div className={styles.buttons}>
                <a href='#about'>
                <button className={styles.learn}>Learn More</button>
                </a>
                <a href='https://filmfreeway.com/Berlinurbanfestival'>
                <button className={styles.submit}>Submit</button>
                </a>
            </div>
        </div>
        </Show>
        </Container>
    );
};

export default Banner