import React, { useState } from 'react';
import styles from './Header.module.scss';
import Container from "../../UI/Container";
import MobileMenu from '../../UI/MobileMenu';

const Header = () => {
    const [menuActive, setMenuActive] = useState(false);

    const handleClick = (e, href) => {
        e.preventDefault();
        const element = document.querySelector(href);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const items = [
        { value: "About", href: '#about' },
        { value: "Awards", href: '#awards' },
        { value: "FAQs", href: '#faqs' },
        { value: "Venues", href: '#venues' },
        { value: "Contact", href: '#contact' },
    ];

    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <div className={styles.logo}>
                        <img src={"/images/logo.png"} alt="Logo" />
                    </div>
                    <div className={styles.headerItems}>
                        {items.map((item, index) => (
                            <div className={styles.items} key={index}>
                                <a href={item.href} onClick={(e) => handleClick(e, item.href)}>
                                    {item.value}
                                </a>
                            </div>
                        ))}
                    </div>
                    <a href="https://filmfreeway.com/Berlinurbanfestival">
                    <div className={styles.button}>Submit Now</div>
                    </a>
                </div>
                <MobileMenu active={menuActive} setActive={setMenuActive} items={items} />
                <div className={styles.mobileMenu}>
                    <div className={styles.burgerButton} onClick={() => setMenuActive(!menuActive)}>
                        <span></span>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Header;
