import styles from './Images.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";

const Images = () => {
    return (
        <Container>
            <Show>
                <div className={styles.imagesContainer}>
                    <img src={"/images/image2.png"} alt="Image 1" className={styles.image} />
                    <img src={"/images/image3.png"} alt="Image 2" className={styles.image} />
                    <img src={"/images/image4.png"}alt="Image 3" className={styles.image} />
                    <img src={"/images/image5.png"} alt="Image 4" className={styles.image} />
                    <img src={"/images/image6.png"} alt="Image 5" className={styles.image} />
                </div>
            </Show>
        </Container>
    );
};

export default Images;
