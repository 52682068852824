import styles from './FAQs.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";

const anq = [
    { ques: "What genres can there be?", ans: "Our festival accepts films of all genres. Don't be afraid to experiment" },
    { ques: "How long should the movie run?", ans: "Films must be no longer than 170 minutes." },
    { ques: "Do films need to have subtitles?", ans: "Submissions must include English subtitles if not in English or German." },
    { ques: "Are entry fees refundable?", ans: "Entry fees are non-refundable." },
    { ques: "What is the production timeframe for films?", ans: "Films must have been produced within the last five years." },
    { ques: "What are the rights requirements for submitted films?", ans: "Filmmakers must own or have all necessary rights for the content they submit. The festival is not responsible for any infringement of third-party rights." },
    { ques: "Can the festival use submitted films for promotional purposes?", ans: "The festival may use up to 2 minutes of any film submitted for promotional purposes." },
    { ques: "What communications might participants receive after submitting?", ans: "By submitting an application, participants agree to receive email communications, including newsletters and promotional offers, from the festival and its partners." }
];

const FAQs = () => {
    return(
            <Container>
                <div className={styles.wrapper} id={'faqs'}>
                    <div className={styles.inner}>
                        <div className={styles.title}>Rules & FAQs</div>
                        <div className={styles.subTitle}>Find answers to common questions about the festival, ticketing, and submissions.</div>
                        <div className={styles.faqs}>
                            {anq.map((faq, index) => (
                                <div key={index} className={styles.faq}>
                                    <div className={styles.question}>{faq.ques}</div>
                                    <div className={styles.answer}>{faq.ans}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
    );
};

export default FAQs;
