import styles from './Still.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const Still = () => {
    return(
        <Show>
            <Container>
                <div className={styles.wrapper} id={'contact'}>
                    <div className={styles.inner}>
                        <div className={styles.title}>Still have questions?</div>
                        <div className={styles.subTitle}>Contact us for further assistance.</div>
                        <div className={styles.mail}>Berlinurbanfilmfest@gmail.com</div>
                    </div>
                </div>
            </Container>
        </Show>
    );
};

export default Still;
