import React from 'react';
import styles from "./Footer.module.scss"
import Container from "../../UI/Container";

const Footer = () => {
    return (
        <div className={styles.body}>
            <Container>
        <div className={styles.wrapper}>
            <div className={styles.text}>
                <div className={styles.rows}>
                    <div className={styles.logo}>
                        <img src="/images/logo.png" alt='' />
                    </div>
                    <div className={styles.row}>
                        <a href={'#about'}>About</a>
                        <a href={'#awards'}>Awards</a>
                        <a href={'#faqs'}>FAQs</a>
                    </div>
                    <div className={styles.row}>
                        <a href={'#venues'}>Venues</a>
                        <a href={'#contact'}>Contact</a>
                        <a href={'https://filmfreeway.com/Berlinurbanfestival'}>Submit</a>
                    </div>
                    <div className={styles.row}>
                    <div className={styles.button}>
                    <a href={'https://filmfreeway.com/Berlinurbanfestival'}>
                        <img src={"/images/click2.png"}/>
                    </a>
                </div>
                    </div>
                </div>

            <div className={styles.line}></div>
            <div className={styles.copyright}>
            Copyright © 2025 Berlin Urban film festivall
            </div>
            </div>
        </div>
            </Container>
        </div>
    );
};

export default Footer;