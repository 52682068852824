import styles from './News.module.scss';
import Container from "../../UI/Container";
import Show from "../../UI/Show";


const News = () => {
    return(
        <Container>
        <Show>
        <div className={styles.wrapper}>
            <div className={styles.words}>
            <div className={styles.tittle}>Stay Updated with Our News on Filmfreeway</div>
            </div>
            <div className={styles.logo}>
            <a href='https://filmfreeway.com/Berlinurbanfestival'>
            <img src={"/images/click.png"}/>
            </a>
            </div>
        </div>
        </Show>
        </Container>
    );
};

export default News