import Header from './components/blocks/Header';
import Banner from './components/blocks/Banner';
import Images from './components/blocks/Images';
import Experience from './components/blocks/Experience';
import About from './components/blocks/About';
import Awards from './components/blocks/Awards';
import Venues from './components/blocks/Venues';
import News from './components/blocks/News';
import FAQs from './components/blocks/Rules';
import Still from './components/blocks/Still';
import Footer from './components/blocks/Footer';


function App() {
  return (
    <div className="App">
      <Header/>
      <Banner/>
      <Images/>
      <Experience/>
      <About/>
      <Awards/>
      <Venues/>
      <News/>
      <FAQs/>
      <Still/>
      <Footer/>
    </div>
  );
}

export default App;
